<template>
    <div class="home-faq">
        <h2 class="header">{{ t('faqAnswers') }}</h2>
        <UAccordion color="gray" variant="soft" size="sm" :items multiple>
            <template #default="{ item, index, open }">
                <UButton color="black" variant="ghost" class="accordeon-button" :ui="{ padding: { sm: 'p-0' } }">
                    {{ item.label }}
                    <template #trailing>
                        <Icon :name="open ? 'ph:minus' : 'ph:plus'" class="ms-auto" />
                    </template>
                </UButton>
            </template>
        </UAccordion>
        <NuxtLink :to="localePath('/faq')" class="my-btn" v-if="!props.hideButton">{{ t('allQuestions') }}</NuxtLink>
    </div>
</template>

<script setup>
const { locale, t } = useI18n()
const localePath = useLocalePath()

const props = defineProps(['faqs', 'hideButton'])
const items = computed(() => props.faqs.map(el => ({
    label: el.question[locale.value],
    content: el.answer[locale.value]
})))
</script>